import React from "react";
import HeroTip from "/app/src/components/HeroUi/Tip";
import {
  ArrowRight,
  ArrowLeft,
  ArrowCircleRight,
  ArrowCircleLeft,
  ExclamationCircle,
  Delete,
  Warning,
  ArrowBoxRight,
  Locked,
  Unlocked,
  DragIndicator,
  Checkmark,
  Undo,
  Plus,
  StarOutlined,
  StarFilled,
  Close,
  InfoCircle,
  QuestionCircle,
  PlusCircle,
  Edit,
  CircleCheck,
  Clip,
  Menu,
  Globe,
  User,
  WifiOff,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronDown,
  Calendar,
  Refresh,
  Drag,
  LinkExternal,
  ToastWarning,
} from "./icons";

type IconName =
  | "ArrowRight"
  | "ArrowLeft"
  | "ArrowCircleRight"
  | "ArrowCircleLeft"
  | "ExclamationCircle"
  | "Delete"
  | "Warning"
  | "ArrowBoxRight"
  | "Locked"
  | "Unlocked"
  | "DragIndicator"
  | "Checkmark"
  | "Undo"
  | "Plus"
  | "StarOutlined"
  | "StarFilled"
  | "Close"
  | "InfoCircle"
  | "QuestionCircle"
  | "PlusCircle"
  | "Edit"
  | "CircleCheck"
  | "Clip"
  | "Menu"
  | "Globe"
  | "User"
  | "WifiOff"
  | "ChevronLeft"
  | "ChevronRight"
  | "ChevronUp"
  | "ChevronDown"
  | "Calendar"
  | "Refresh"
  | "Drag"
  | "LinkExternal"
  | "ToastWarning";

interface IconBuilderProps {
  icon: IconName;
  size?: number;
  color?: string;
  onClick?: (() => void) | ((e: React.MouseEvent) => void);
  className?: string;
}

interface IconToolHeroTipProps {
  content: string;
  icon?: IconName;
  size?: number;
  color?: string;
  onClick?: () => void;
  tipPlacement?:
    | "top"
    | "bottom"
    | "right"
    | "left"
    | "top-start"
    | "top-end"
    | "bottom-start"
    | "bottom-end"
    | "right-start"
    | "right-end"
    | "left-start"
    | "left-end";
  className?: string;
}

const iconComponents = {
  ArrowRight,
  ArrowLeft,
  ArrowCircleRight,
  ArrowCircleLeft,
  ExclamationCircle,
  Delete,
  Warning,
  ArrowBoxRight,
  Locked,
  Unlocked,
  DragIndicator,
  Checkmark,
  Undo,
  Plus,
  StarOutlined,
  StarFilled,
  Close,
  InfoCircle,
  QuestionCircle,
  PlusCircle,
  Edit,
  CircleCheck,
  Clip,
  Menu,
  Globe,
  User,
  WifiOff,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronDown,
  Calendar,
  Refresh,
  Drag,
  LinkExternal,
  ToastWarning,
};

/**
 * Retrieves the specified icon component and renders it with the given size and color.
 *
 * @param {string} iconName - The name of the icon to retrieve.
 * @param {number} size - The size of the icon.
 * @param {string} color - The color of the icon.
 * @returns {JSX.Element | null} The rendered icon component, or null if the icon is not found.
 */
const getIcon = (iconName: string, size: number, color: string) => {
  const IconComponent = iconComponents[iconName];

  if (IconComponent) {
    return <IconComponent size={size} color={color} />;
  }

  return null;
};

/**
 *
 * @param param0 icon, size and color
 * @returns Icon
 */
const IconBuilder: React.FC<IconBuilderProps> = ({
  icon,
  size = 24,
  color = "#202023",
  onClick,
  className,
}) => {
  const Icon = getIcon(icon, size, color);
  return !onClick ? (
    <div className={className}>{Icon}</div>
  ) : (
    <button className={className} onClick={onClick}>
      {Icon}
    </button>
  );
};

/**
 *
 * @param param0 content, icon, size, color, onClick, tipPlacement, className
 * @returns Icon with tooltip
 */
const IconToolTip: React.FC<IconToolHeroTipProps> = ({
  content,
  icon = "QuestionCircle",
  size = 18,
  color = "#202023",
  onClick,
  tipPlacement = "top",
  className,
}) => {
  const Icon = getIcon(icon, size, color);

  return !onClick ? (
    <HeroTip placement={tipPlacement} content={content}>
      <div className={className}>{Icon}</div>
    </HeroTip>
  ) : (
    <button className={className} onClick={onClick} onKeyDown={onClick}>
      <HeroTip placement={tipPlacement} content={content}>
        <div>{Icon}</div>
      </HeroTip>
    </button>
  );
};

export { IconBuilder, IconToolTip };
