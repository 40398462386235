import { useQuery } from "@tanstack/react-query";
import { Checkbox, Col, Row } from "antd";
import { useCallback, useEffect, useState } from "react";
import { PowerPickTimeZoneToLocal } from "/app/src/components/generic/formatting/dateTime";
import { HistoryTypes } from "/app/src/constants/types";
import { Transaction, Material } from "/app/src/models";
import { transactionService, materialService } from "/app/src/services";
import { useNavigate, Link } from "react-router-dom";
import ClickableCell from "/app/src/components/generic/components/clickableCell";
import HeroSpin from "/app/src/components/HeroUi/Spin";
import HeroAccordion from "/app/src/components/HeroUi/Accordion";
import { AccordionItem } from "@heroui/react";
import Box from "/app/src/components/generic/components/box";
import Descriptions from "/app/src/components/generic/components/descriptions";
import { HeroChip } from "/app/src/components/HeroUi/Chip";
import { useTranslation } from "react-i18next";
import { IconBuilder } from "/app/src/components/icons/IconBuilder";

/**
 * Returns a text representation of the export state type
 * @param exportStateType number to convert to string
 * @returns
 */
const getStatusProps = (
  exportStateType: number,
): {
  colorClass:
    | "success"
    | "orange"
    | "danger"
    | "primary"
    | "secondary"
    | "default"
    | "warning";
  text: string;
} => {
  switch (exportStateType) {
    case 4:
      return { colorClass: "success", text: "Success" };
    case 11:
    case 3:
      return { colorClass: "orange", text: "Sending" };
    case 5:
      return { colorClass: "danger", text: "Failed" };
    case 10:
      return { colorClass: "orange", text: "Sending" };
    case 12:
      return {
        colorClass: "primary",
        text: "Awaiting Approval",
      };
    default:
      return {
        colorClass: "warning",
        text: `Unknown Type: ${exportStateType}`,
      };
  }
};

/**
 * Renders a status badge based on the export state type.
 * @param {string} exportStateType - The export state type.
 * @returns {JSX.Element} The rendered status badge.
 */
const StatusBadge = ({ exportStateType }) => {
  const { colorClass, text } = getStatusProps(exportStateType);

  return (
    <HeroChip size="sm" color={colorClass}>
      {text}
    </HeroChip>
  );
};

/**
 * Shows a single row of a failed transaction
 * Each row controls its own retrying state using useQuery and useEffect
 */
export default function TransactionRow({
  transaction,
  selectedRowIds,
  setSelectedRowIds,
  ordersRetrying,
  setOrdersRetrying,
  exportStateType,
}: {
  transaction: Transaction;
  selectedRowIds: string[];
  setSelectedRowIds: (selectedRowIds: string[]) => void;
  ordersRetrying: string[];
  setOrdersRetrying: (ordersRetrying: string[]) => void;
  exportStateType: 5 | 12;
}) {
  const [enabled, setEnabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data } = useQuery({
    queryKey: ["failedTransaction", transaction.id],
    queryFn: () => {
      return transactionService.getSingle(transaction.id);
    },
    initialData: { transaction },
    enabled,
    refetchInterval: 3000,
  });

  useEffect(() => {
    //if the transaction is in a retryable state, enable the query to fetch
    //every three seconds
    if ([3, 10, 11].includes(data.transaction?.exportStateType)) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  }, [data.transaction?.exportStateType]);

  useEffect(() => {
    //if the transaction failed, remove it from the retrying list
    if (
      data.transaction?.exportStateType !== 10 &&
      ordersRetrying.includes(data.transaction.id)
    ) {
      setOrdersRetrying(
        ordersRetrying.filter((id) => id !== data.transaction.id),
      );
    }
  }, [
    data.transaction?.exportStateType,
    ordersRetrying,
    data.transaction.id,
    setOrdersRetrying,
  ]);

  const transactionIdClick = useCallback(
    (transactionId: string) => {
      navigate(`/explorer/transactions/${transactionId}`);
    },
    [navigate],
  );

  const actionsClicked = useCallback(() => {
    if (selectedRowIds.includes(transaction.id)) {
      setSelectedRowIds(
        selectedRowIds.filter(
          (selectedRowId) => selectedRowId !== transaction.id,
        ),
      );
    } else {
      setSelectedRowIds([...selectedRowIds, transaction.id]);
    }
  }, [selectedRowIds, setSelectedRowIds, transaction.id]);

  const { data: materials } = useQuery({
    queryKey: ["materials"],
    queryFn: () => {
      return materialService.getAll();
    },
    initialData: { materials: [] },
    select: (data: { materials: Material[] }) => {
      return data.materials;
    },
  });

  //Check if the material exists in the materials list
  const materialId = materials.find(
    (material) => material.name === data.transaction?.materialName,
  )?.id;

  const toggleIndicatorIcon = useCallback(({ isOpen }) => {
    return isOpen ? (
      <IconBuilder icon="ChevronRight" />
    ) : (
      <IconBuilder icon="ChevronDown" />
    );
  }, []);

  return (
    <Box>
      <Row gutter={20}>
        <Col span={6} className="name">
          <ClickableCell
            cellValue={data.transaction.id}
            clickFn={transactionIdClick}
            parameter={data.transaction.id}
          />
        </Col>
        <Col span={6} className="name">
          {data.transaction.orderName}
        </Col>
        <Col span={3}>
          {
            HistoryTypes.find((type) => type.value === data.transaction.type)
              ?.name
          }
        </Col>
        <Col span={3}>
          <StatusBadge exportStateType={data.transaction?.exportStateType} />
        </Col>
        <Col span={1}>
          {[3, 10, 11].includes(data.transaction?.exportStateType) && (
            <HeroSpin size="sm" />
          )}
        </Col>

        <Col span={4}>
          <PowerPickTimeZoneToLocal
            date={data.transaction.creationDate}
            format={"MMMM Do YYYY, h:mm:ss.SS a"}
          />
        </Col>
        <Col span={1} className="actions">
          <div className="titleActions">
            <Checkbox
              checked={selectedRowIds.includes(transaction.id)}
              onClick={actionsClicked}
              disabled={data.transaction?.exportStateType !== exportStateType}
            />
          </div>
        </Col>
      </Row>
      <HeroAccordion
        itemClasses={{
          heading: "w-[125px]",
          trigger: "py-0",
        }}
        fullWidth={false}
        selectionMode="single"
      >
        <AccordionItem
          classNames={{
            title: "text-sm",
          }}
          indicator={toggleIndicatorIcon}
          key="1"
          title={t("translation:see_details")}
        >
          <Descriptions
            itemsPerRow={2}
            bordered
            items={[
              {
                label: t("translation:order_line_number"),
                value: (
                  <Link
                    to={`/explorer/order_lines/${data.transaction?.orderLineId}`}
                  >
                    {data.transaction?.number}
                  </Link>
                ),
              },
              {
                label: t("translation:material_name"),
                value: (
                  <Link to={`/explorer/materials/${materialId}`}>
                    {data.transaction?.materialName}
                  </Link>
                ),
              },
              {
                label: t("translation:confirmed_quantity"),
                value: data.transaction?.quantityConfirmed,
              },
              {
                label: t("translation:deviated_quantity"),
                value: data.transaction?.quantityDeviated,
              },
            ]}
          />
        </AccordionItem>
      </HeroAccordion>
    </Box>
  );
}
